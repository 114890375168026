import React from "react";
import {
  useGetLocationIncome,
  useGetBusinessIncome,
} from "../Locations/LocationsUtils";
import firebase from "firebase";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  business,
  vehicles,
  firestore,
  incidents,
} from "../../../../firebase/firebasejs";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import VehicleDetailContainer from "../VehicleDetail/VehicleDetailContainer";
import {
  useTotalAmount,
  useTotalAmountWidhValidations,
  useTotalAmountWidhValidationsAndDates,
} from "../TicketsValidation/PaymentUtils";
import { fecha, fechahora, timestampToDate } from "../../../../services/utils";

export function roundNumber(number) {
  return Math.round(number * 100) / 100;
}

export function timestampToOnlyDate(timestamp) {
  var fecha = new Date(timestamp * 1000);
  var dia = fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate();
  var mes =
    fecha.getMonth() + 1 < 10
      ? "0" + (fecha.getMonth() + 1)
      : fecha.getMonth() + 1;
  var year = fecha.getFullYear();
  var fechaFinal = dia + "/" + mes + "/" + year;
  return fechaFinal;
}

function milisecondsToDHHMMSS(miliseconds) {
  const cd = 24 * 60 * 60 * 1000;
  const ch = 60 * 60 * 1000;
  const cm = 60 * 1000;
  let d = Math.floor(miliseconds / cd);
  let h = Math.floor((miliseconds - d * cd) / ch);
  let m = Math.floor((miliseconds - d * cd - h * ch) / 60000);
  let s = Math.round((miliseconds - d * cd - h * ch - m * cm) / 1000);
  const pad = function (n) {
    return n < 10 ? "0" + n : n;
  };
  if (s === 60) {
    m++;
    s = 0;
  }
  if (m === 60) {
    h++;
    m = 0;
  }
  if (h === 24) {
    d++;
    h = 0;
  }
  return [d, pad(h), pad(m), pad(s)].join(":");
}

export const longColorList = [
  "#000000",
  "#800000",
  "#008000",
  "#808000",
  "#000080",
  "#800080",
  "#008080",
  "#c0c0c0",
  "#ff0000",
  "#00ff00",
  "#ffff00",
  "#0000ff",
  "#808080",
  "#ff00ff",
  "#00ffff",
  "#ffffff",
  "#000000",
  "#00005f",
  "#005f00",
  "#00ffd7",
  "#5f0000",
  "#005f5f",
  "#5f005f",
  "#5f5f00",
  "#000087",
  "#5f5f5f",
  "#5f875f",
  "#afaf00",
  "#afd7ff",
  "#5f5f87",
  "#0000af",
  "#00ffff",
  "#5f0087",
  "#5f5faf",
  "#5f8787",
  "#0000d7",
  "#870000",
  "#af0087",
  "#5f00af",
  "#5f5fd7",
  "#0000ff",
  "#87005f",
  "#8700d7",
  "#af005f",
  "#5f00d7",
  "#5f00ff",
  "#5f87af",
  "#870087",
  "#af5f00",
  "#875f00",
  "#8700ff",
  "#875f5f",
  "#af5f87",
  "#5f5fff",
  "#5f8700",
  "#8700af",
  "#af8700",
  "#875f87",
];

export function getHHMMSS(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours}:${minutes}:${seconds}`;
}

export function getBusinessIncome({ businessLocations, vehicles }) {
  const businessIncome = -useGetBusinessIncome(vehicles);

  // const locationsUnique = [
  //   ...new Set(Object.values(vehicles).map((item) => item.locationId)),
  // ];
  // for each businesslocation get income
  let locationsIncome = {};
  if (businessLocations) {
    Object.keys(businessLocations)
      .filter((a) => businessLocations[a].authorized)
      .forEach(
        (locationKey) =>
          (locationsIncome[locationKey] = -useGetLocationIncome(
            locationKey,
            vehicles
          ))
      );
  } else {
    console.log("no business locations yet.");
  }
  return { total: businessIncome, locations: locationsIncome };
}

export function getVehiclesByDay(vehicles) {
  if (vehicles) {
    const vehiclesByDay = {};
    Object.keys(vehicles).forEach((vehicleKey) => {
      let vehicle = vehicles[vehicleKey];
      let vehicleDate = timestampToOnlyDate(vehicle.startTime.seconds);
      if (vehiclesByDay[vehicleDate]) {
        vehiclesByDay[vehicleDate].vehicles.push(vehicle);
      } else {
        vehiclesByDay[vehicleDate] = { vehicles: [vehicle] };
      }
    });
    return vehiclesByDay;
  } else {
    return undefined;
  }
}

export function sortDDMMYYYDates(a, b) {
  function DDMMYYYYtoDate(DMY) {
    var dateParts = DMY.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject;
  }
  return DDMMYYYYtoDate(a) - DDMMYYYYtoDate(b);
}

function getLocationVehicles(
  businessId,
  locationId,
  startDate,
  endDate,
  businessLocations
) {
  console.log(locationId);
  if (!businessLocations[locationId].authorized) {
    return {};
  }
  var Ref = firestore
    .collection("business")
    .doc(businessId)
    .collection("locations")
    .doc(locationId)
    .collection(vehicles)
    .where("startTime", ">=", startDate)
    .where("startTime", "<=", endDate);

  return Ref.get().then((querySnapshot) => {
    if (querySnapshot == undefined) {
      return;
    }
    let vehiclesReport = {};
    querySnapshot.forEach((doc) => {
      const vehicleNew = {
        ...doc.data(),
        id: doc.id,
        locationId: locationId,
      };
      vehiclesReport[doc.id] = vehicleNew;
    });
    console.log("VehiclesList: ", locationId);
    console.log("VehiclesList: ", vehiclesReport);
    return vehiclesReport;
  });
}
function getLocationIncidents(
  businessId,
  locationId,
  startDate,
  endDate,
  businessLocations
) {
  console.log(locationId);
  if (!businessLocations[locationId].authorized) {
    return {};
  }
  var Ref = firestore
    .collection("business")
    .doc(businessId)
    .collection("locations")
    .doc(locationId)
    .collection(incidents)
    .where(
      "date",
      ">=",
      firebase.firestore.Timestamp.fromDate(new Date(startDate))
    )
    .where(
      "date",
      "<=",
      firebase.firestore.Timestamp.fromDate(new Date(endDate))
    );

  return Ref.get().then((querySnapshot) => {
    if (querySnapshot == undefined) {
      return;
    }
    let incidentsReport = {};
    querySnapshot.forEach((doc) => {
      const incidentNew = {
        ...doc.data(),
        id: doc.id,
        locationId: locationId,
      };
      incidentsReport[doc.id] = incidentNew;
    });
    console.log("VehiclesList: ", locationId);
    console.log("VehiclesList: ", incidentsReport);
    return incidentsReport;
  });
}
function getLocationVehiclesPromise(
  businessId,
  locationId,
  startDate,
  endDate,
  businessLocations
) {
  if (locationId == "all_locations") {
    let promises = [];

    for (var i in businessLocations) {
      if (businessLocations[i].authorized) {
        promises.push(
          getLocationVehicles(
            businessId,
            i,
            startDate,
            endDate,
            businessLocations
          )
        );
      }
    }
    console.log("values", promises);
    return Promise.all(promises)
      .then((values) => {
        let vehicles = {};
        for (var i in values) {
          let locationList = values[i];
          for (var e in locationList) {
            vehicles[e] = locationList[e];
          }
        }
        return vehicles;
      })
      .catch((e) => {
        return {};
      });
  } else {
    return getLocationVehicles(
      businessId,
      locationId,
      startDate,
      endDate,
      businessLocations
    );
  }
}
function getLocationIncidentsPromise(
  businessId,
  locationId,
  startDate,
  endDate,
  businessLocations
) {
  if (locationId == "all_locations") {
    let promises = [];

    for (var i in businessLocations) {
      if (businessLocations[i].authorized) {
        promises.push(
          getLocationIncidents(
            businessId,
            i,
            startDate,
            endDate,
            businessLocations
          )
        );
      }
    }
    console.log("values", promises);
    return Promise.all(promises)
      .then((values) => {
        let incidents = {};
        for (var i in values) {
          let locationList = values[i];
          for (var e in locationList) {
            incidents[e] = locationList[e];
          }
        }
        return incidents;
      })
      .catch((e) => {
        return {};
      });
  } else {
    return getLocationIncidents(
      businessId,
      locationId,
      startDate,
      endDate,
      businessLocations
    );
  }
}

export function generateReport({
  languageObject,
  businessId,
  reportData,
  businessLocations,
  businessUsers,
}) {
  let vehicles = {};
  let vehiclesReport = {};

  const { reportType, locations, startDate, endDate } = reportData;
  const reportTexts = languageObject.authorizeUserdViews.reports;
  const reportTypes = reportTexts.reportTypes;
  const texts = reportTexts.reportDefinitions;

  return getLocationVehiclesPromise(
    businessId,
    reportData.locations,
    reportData.startDate,
    reportData.endDate,
    businessLocations
  )
    .then((vehiclesReportData) => {
      vehiclesReport = vehiclesReportData;

      if (reportData.locations === "all_locations") {
        console.log("vehiclesReport", vehiclesReport);
        vehicles = vehiclesReport;
      } else {
        vehicles = vehiclesReport;
      }

      if (reportTexts.reportTypes !== "incidents") {
        return getLocationIncidentsPromise(
          businessId,
          reportData.locations,
          reportData.startDate,
          reportData.endDate,
          businessLocations
        );
      } else {
        return {};
      }
    })
    .then((incidentsReport) => {
      console.log(vehiclesReport);

      console.log("vehiclesReport", vehicles);
      console.log("vehiclesReport", reportData);
      console.log("vehiclesReport", businessLocations);
      console.log("vehiclesReport", businessUsers);
      console.log("vehiclesReportDate", reportData.startDate.getTime() / 1000);
      console.log("vehiclesReportDate", reportData.endDate.getTime() / 1000);

      const { reportType, locations, startDate, endDate } = reportData;
      const reportTexts = languageObject.authorizeUserdViews.reports;
      const reportTypes = reportTexts.reportTypes;
      const texts = reportTexts.reportDefinitions;
      // Aplica filtros de fecha y ubicacion
      const reportVehiclesArr = Object.values(vehicles);

      console.log("vehiclesReportType", reportType);

      // Crea lista de vehiculos usando la lista de vehicle keys de arriba
      const reportVehicles = {};

      // Ordenar la lista por fecha, crea un objecto de fechas, cada fecha con su array de vehiculos
      const reportVehiclesByDay = getVehiclesByDay(reportVehiclesArr);

      let csvData = [];
      let header = [];
      let body = [];
      let footer = [];
      let title;
      let charts = [];
      //  a chart looks like { title, description, image};
      if (reportType === "general") {
        title = `${
          locations === "all_locations"
            ? reportTexts.allLocations
            : businessLocations[locations].locationConfig.name
        } / ${reportTypes.general}: ${reportData.startDate} - ${
          reportData.endDate
        }`;
        header = [texts.date, texts.income, texts.traffic];
        Object.keys(reportVehiclesByDay).forEach((date) => {
          let dateTraffic = reportVehiclesByDay[date].vehicles.length;
          let dateIncome = 0;

          reportVehiclesByDay[date].vehicles.forEach((vehicle) => {
            var payments = vehicle.payment.list;
            let income = 0;
            for (var e in payments) {
              if (!payments[e].deleted) {
                income += payments[e].amount;
              }
            }
            dateIncome += income;
          });
          body.push([
            date,
            `$${(-dateIncome).toString()}`,
            dateTraffic.toString(),
          ]);
        });
        const incomeReducer = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[1].substring(1), 10);
        const trafficReducer = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[2], 10);

        const incomeTotal = body.reduce(incomeReducer, 0);
        const trafficTotal = body.reduce(trafficReducer, 0);

        const trafficByDateChartData = getTrafficByDateChartData(
          reportVehiclesByDay,
          reportTexts
        );
        let trafficByDateChartImage = <Line data={trafficByDateChartData} />;
        const trafficByDateChart = {
          title: reportTexts.traffic,
          description: `${reportTexts.trafficDescription}: ${reportVehiclesArr.length}`,
          image: trafficByDateChartImage,
        };

        let reportLocation;
        if (locations === "all_locations") {
          reportLocation = businessLocations;
        } else {
          reportLocation = { [locations]: { ...businessLocations[locations] } };
        }

        const incomeReport = getBusinessIncome({
          businessLocations: reportLocation,
          vehicles: reportVehicles,
        });
        const incomeByLocationChartData = getIncomeByLocationChartData(
          incomeReport,
          businessLocations
        );
        const incomeByLocationChartImage = (
          <Pie data={incomeByLocationChartData} />
        );
        const incomeByLocationChart = {
          title: reportTexts.income,
          description: `${reportTexts.incomeDescription}: ${
            Math.round(incomeReport.total + 100) / 100
          }`,
          image: incomeByLocationChartImage,
        };
        if (!reportType) {
          charts === null;
        } else {
          charts.push(incomeByLocationChart);
          charts.push(trafficByDateChart);
        }

        footer = [
          "Total",
          `$${incomeTotal.toString()} `,
          trafficTotal.toString(),
        ];
      } else if (reportType === "income") {
        title = `${
          locations === "all_locations"
            ? reportTexts.allLocations
            : businessLocations[locations].locationConfig.name
        } / ${reportTypes.income}: ${reportData.startDate} - ${
          reportData.endDate
        } `;
        header = [
          texts.vehicle,
          texts.date,
          texts.location,
          texts.rate,
          texts.gate,
          texts.cash,
          texts.pos,
          texts.online,
          texts.auth,
          texts.pay,
          texts.pendingPay,
        ];
        // Esta linea asume que estos son los metodos de pago posibles.
        // No se puede acerder a la lista sin estar dentro de un locaiton
        let paymentMethodCount = {
          pos: 0,
          cash: 0,
          onlinePayment: 0,
          auth: 0,
          Tienda: 0,
        };
        Object.keys(reportVehiclesByDay).forEach((date) => {
          reportVehiclesByDay[date].vehicles.forEach((vehicle) => {
            let vehiclePlate = vehicle.plateNumber || `id - ${vehicle.id} `;
            let rate =
              businessLocations[vehicle.locationId].locationConfig.payment
                .rateConfig[vehicle.rate].name;
            let gate =
              businessLocations[vehicle.locationId].locationConfig &&
              businessLocations[vehicle.locationId].locationConfig.payment &&
              businessLocations[vehicle.locationId].locationConfig.payment
                .gateConfig &&
              businessLocations[vehicle.locationId].locationConfig.payment
                .gateConfig[vehicle.gate] &&
              businessLocations[vehicle.locationId].locationConfig.payment
                .gateConfig[vehicle.gate].name
                ? businessLocations[vehicle.locationId].locationConfig.payment
                    .gateConfig[vehicle.gate].name
                : "N/A";
            let location =
              businessLocations[vehicle.locationId].locationConfig.name;
            const charges = useTotalAmountWidhValidationsAndDates(
              businessLocations[vehicle.locationId].locationConfig.payment
                .rateConfig,
              vehicle,
              reportData.startDate.getTime() / 1000,
              reportData.endDate.getTime() / 1000
            );
            let payment = 0;
            let paymentMethodsArr = [];
            var payments = vehicle.payment.list;
            for (var e in payments) {
              if (!payments[e].deleted) {
                payment += payments[e].amount;
                paymentMethodCount[payments[e].paymentMethod] += -payments[e]
                  .amount;
                paymentMethodsArr.push(
                  `$${-payments[e].amount}: ${payments[e].paymentMethod} `
                );
              }
            }
            body.push([
              vehiclePlate,
              date,
              location,
              rate,
              gate,
              roundNumber(charges.payByType.cash),
              roundNumber(charges.payByType.pos),
              roundNumber(charges.payByType.onlinePayment),
              roundNumber(charges.payByType.auth),
              roundNumber(charges.charge - charges.pendingPay),
              roundNumber(charges.pendingPay),
            ]);
          });
        });
        // ##########################################################################
        const incomeReducer = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[8]);
        const incomeTotal = body.reduce(incomeReducer, 0);
        footer = [
          "",
          "",
          "",
          "",
          "Total",
          roundNumber(paymentMethodCount["cash"]),
          roundNumber(paymentMethodCount["pos"]),
          roundNumber(paymentMethodCount["onlinePayment"]),
          roundNumber(paymentMethodCount["auth"]),
          `$${incomeTotal.toString()} `,
          "",
        ];

        // ##########################################################################
        function formatPaymentMethodStats(paymentMethodCount) {
          let chartLabels = [];
          let chartValues = [];
          console.log("chartValues", paymentMethodCount);

          Object.entries(paymentMethodCount).forEach((keyVal) => {
            chartLabels.push(keyVal[0]);
            chartValues.push(keyVal[1]);
          });
          const methodStats = {
            labels: chartLabels,
            datasets: [
              {
                backgroundColor: ["#9e9e9e", "#000", "#fff"],
                data: chartValues,
              },
            ],
          };
          console.log("chartValues", chartValues);

          let totalPayments = chartValues.reduce((a, b) => a + b);
          return { methodStats, totalPayments };
        }
        // ##########################################################################
        function userValidationRegistryStats(validationsList) {
          let chartLabels = [];
          let chartValues = [];
          console.log("chartValues", validationsList);

          Object.entries(validationsList).forEach((keyVal) => {
            chartLabels.push(keyVal[0]);
            chartValues.push(keyVal[1]);
          });
          const methodStats = {
            labels: chartLabels,
            datasets: [
              {
                backgroundColor: ["#9e9e9e", "#000", "#fff"],
                data: chartValues,
              },
            ],
          };
          console.log("chartValues", chartValues);

          let totalPayments = chartValues.reduce((a, b) => a + b);
          return { methodStats, totalPayments };
        }
        // ##########################################################################
        let formattedPaymentMethodStats = formatPaymentMethodStats(
          paymentMethodCount
        );

        let paymentMethodPie = (
          <Pie data={formattedPaymentMethodStats.methodStats} />
        );
        const paymentMethodChart = {
          title: texts.paymentMethodPie,
          description: `${texts.totalPayments}: ${roundNumber(
            formattedPaymentMethodStats.totalPayments
          )} `,
          image: paymentMethodPie,
        };
        charts.push(paymentMethodChart);
      } else if (reportType === "incomeOnlyParking") {
        title = `${
          locations === "all_locations"
            ? reportTexts.allLocations
            : businessLocations[locations].locationConfig.name
        } / ${reportTypes.income}: ${reportData.startDate} - ${
          reportData.endDate
        } `;
        header = [
          texts.vehicle,
          texts.date,
          texts.location,
          texts.rate,
          texts.pay,
          texts.startTime,
          texts.endTime,
        ];
        console.log("incomeOnlyParking", reportType);
        // Esta linea asume que estos son los metodos de pago posibles.
        // No se puede acerder a la lista sin estar dentro de un locaiton
        let paymentMethodCount = {
          pos: 0,
          cash: 0,
          onlinePayment: 0,
          auth: 0,
          Tienda: 0,
        };
        Object.keys(reportVehiclesByDay).forEach((date) => {
          reportVehiclesByDay[date].vehicles.forEach((vehicle) => {
            let vehiclePlate = vehicle.plateNumber || `id - ${vehicle.id} `;
            let rate =
              businessLocations[vehicle.locationId].locationConfig.payment
                .rateConfig[vehicle.rate].name;
            let valetRate =
              businessLocations[vehicle.locationId].locationConfig.payment
                .rateConfig[vehicle.rate].valetRate;

            let location =
              businessLocations[vehicle.locationId].locationConfig.name;
            const charges = useTotalAmountWidhValidationsAndDates(
              businessLocations[vehicle.locationId].locationConfig.payment
                .rateConfig,
              vehicle,
              reportData.startDate.getTime() / 1000,
              reportData.endDate.getTime() / 1000
            );
            let payment = 0;
            let paymentMethodsArr = [];
            var payments = vehicle.payment.list;
            for (var e in payments) {
              if (!payments[e].deleted) {
                payment += payments[e].amount;
                paymentMethodCount[payments[e].paymentMethod] += -payments[e]
                  .amount;
                paymentMethodsArr.push(
                  `$${-payments[e].amount}: ${payments[e].paymentMethod} `
                );
              }
            }

            const totalCharge =
              roundNumber(
                (charges.charge - (valetRate ? valetRate : 0)) * 100
              ) / 100;
            const closed =
              vehicle.changes &&
              Object.values(vehicle.changes).filter(
                (a) => a.atribute === "closed"
              )[0];
            const endTime = closed && closed.date;

            console.log(
              "incomeOnlyParking",
              reportType,
              valetRate,
              totalCharge,
              charges.charge,
              vehiclePlate,
              timestampToDate(vehicle.startTime.seconds * 1000),
              timestampToDate(endTime * 1000)
            );

            if (totalCharge > 0) {
              body.push([
                vehiclePlate,
                date,
                location,
                rate,
                totalCharge,
                timestampToDate(vehicle.startTime.seconds * 1000),
                timestampToDate(endTime * 1000),
              ]);
            }
          });
        });
        // ##########################################################################
        const incomeReducer = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[4]);
        const incomeTotal = body.reduce(incomeReducer, 0);
        // let totalNew = 0;
        // for (var i in body) {
        //   tota;
        // }
        footer = [
          "",
          "",
          "",
          "Total",
          `$${Math.round(incomeTotal * 100) / 100} `,
        ];

        // ##########################################################################
        function formatPaymentMethodStats(paymentMethodCount) {
          let chartLabels = [];
          let chartValues = [];
          console.log("chartValues", paymentMethodCount);

          Object.entries(paymentMethodCount).forEach((keyVal) => {
            chartLabels.push(keyVal[0]);
            chartValues.push(keyVal[1]);
          });
          const methodStats = {
            labels: chartLabels,
            datasets: [
              {
                backgroundColor: ["#9e9e9e", "#000", "#fff"],
                data: chartValues,
              },
            ],
          };
          console.log("chartValues", chartValues);

          let totalPayments = chartValues.reduce((a, b) => a + b);
          return { methodStats, totalPayments };
        }
        // ##########################################################################
        let formattedPaymentMethodStats = formatPaymentMethodStats(
          paymentMethodCount
        );

        let paymentMethodPie = (
          <Pie data={formattedPaymentMethodStats.methodStats} />
        );
        const paymentMethodChart = {
          title: texts.paymentMethodPie,
          description: `${texts.totalPayments}: ${formattedPaymentMethodStats.totalPayments} `,
          image: paymentMethodPie,
        };
        charts.push(paymentMethodChart);
      } else if (reportType === "turnoverTime") {
        title = `${
          locations === "all_locations"
            ? reportTexts.allLocations
            : businessLocations[locations].locationConfig.name
        } / ${reportTypes.turnoverTime}: ${reportData.startDate} - ${
          reportData.endDate
        } `;
        header = [
          texts.vehicle,
          texts.location,
          texts.startTime,
          texts.endTime,
          texts.turnoverTime,
          texts.detail,
        ];
        let avgTurnover = 0;
        let numberOfTurnovers = 0;
        Object.keys(reportVehiclesByDay).forEach((date) => {
          reportVehiclesByDay[date].vehicles.forEach((vehicle) => {
            let vehiclePlate = vehicle.plateNumber || `id - ${vehicle.id} `;
            let location =
              businessLocations[vehicle.locationId].locationConfig.name;
            let changes = vehicle.changes;
            let endChange = Object.keys(vehicle.changes).filter(
              (key) =>
                changes[key].atribute === "status" &&
                changes[key].newValue === "taken"
            );
            let startTimestamp = new Date(vehicle.startTime.seconds * 1000);
            //console.log("startTime", startTimestamp);
            let startTime = new Date(startTimestamp).toLocaleString("es-MX");
            if (endChange[0]) {
              let endTimestamp = changes[endChange[0]].date * 1000;
              let endTime = new Date(endTimestamp).toLocaleString("es-MX");

              const diffTime = Math.abs(
                new Date(endTimestamp) - new Date(startTimestamp)
              );
              avgTurnover += diffTime;
              numberOfTurnovers += 1;

              let turnoverTime = `${milisecondsToDHHMMSS(diffTime)} `;
              body.push([
                vehiclePlate,
                location,
                startTime,
                endTime,
                turnoverTime,
                <SimpleDialogDemo buttonText={texts.openDetail}>
                  <VehicleDetailContainer vehicle={vehicle} />
                </SimpleDialogDemo>,
              ]);
            } else {
              body.push([vehiclePlate, location, startTime, "N/A", "N/A"]);
            }
          });
        });
        footer = [
          "",
          "",
          "",
          texts.avgTurnover,
          milisecondsToDHHMMSS(avgTurnover / numberOfTurnovers),
        ];
        let hourlyTrafficChartImage = (
          <Bar data={getHourlyTraffic(reportVehicles)} />
        );
        const hourlyTrafficChart = {
          title: texts.hourlyTraffic,
          description: `${texts.totalTraffic}: ${
            Object.keys(reportVehicles).length
          } `,
          image: hourlyTrafficChartImage,
        };
        charts.push(hourlyTrafficChart);
      } else if (reportType === "vehicleDetails") {
        title = `${
          locations === "all_locations"
            ? reportTexts.allLocations
            : businessLocations[locations].locationConfig.name
        } / ${reportTypes.vehicleDetails}: ${reportData.startDate} - ${
          reportData.endDate
        } `;

        const additionalItemsHeader =
          locations !== "all_locations" &&
          businessLocations[locations].locationConfig.additionalItems !==
            undefined
            ? Object.values(
                businessLocations[locations].locationConfig.additionalItems
              ).map((a) => a.name)
            : [];
        header = [
          texts.detail,
          texts.vehicle,
          texts.make,
          texts.color,
          texts.location,
          texts.date,
          texts.rate,
          texts.gate,
          ...additionalItemsHeader,
        ];
        let vehicleMakes = {};
        let sortedDates = Object.keys(reportVehiclesByDay).sort(
          sortDDMMYYYDates
        );
        sortedDates.forEach((date) => {
          reportVehiclesByDay[date].vehicles.forEach((vehicle) => {
            let vehiclePlate = vehicle.plateNumber || `id - ${vehicle.id} `;
            let location =
              businessLocations[vehicle.locationId].locationConfig.name;
            let make = vehicle.brand;
            if (!vehicleMakes[make]) {
              vehicleMakes[make] = 1;
            } else {
              vehicleMakes[make] += 1;
            }

            const additionalItemsRow = additionalItemsHeader.map((a) =>
              vehicle.additionalItems !== undefined &&
              vehicle.additionalItems[a] !== undefined
                ? typeof vehicle.additionalItems[a] === "boolean"
                  ? vehicle.additionalItems[a]
                    ? "YES"
                    : "NO"
                  : vehicle.additionalItems[a]
                : ""
            );
            let rate =
              vehicle.rate !== undefined
                ? businessLocations[vehicle.locationId].locationConfig.payment
                    .rateConfig[vehicle.rate].name
                : "";
            let gate =
              vehicle.gate !== undefined
                ? businessLocations[vehicle.locationId].locationConfig.payment
                    .gateConfig[vehicle.gate].name
                : "NA";

            body.push([
              <SimpleDialogDemo buttonText={texts.openDetail}>
                <VehicleDetailContainer vehicle={vehicle} />
              </SimpleDialogDemo>,
              vehiclePlate,
              make,
              vehicle.color,
              location,
              date,
              rate,
              gate,
              ...additionalItemsRow,
            ]);
          });
        });
        function formatVehicleMakeStats(vehicleMakes) {
          let chartLabels = [];
          let chartValues = [];
          Object.entries(vehicleMakes).forEach((keyVal) => {
            chartLabels.push(keyVal[0]);
            chartValues.push(keyVal[1]);
          });
          const makeStats = {
            labels: chartLabels,
            datasets: [
              {
                backgroundColor: longColorList,
                borderColor: "#000",
                borderWidth: 1,
                data: chartValues,
              },
            ],
          };
          return makeStats;
        }

        let formattedVehicleMakeStats = formatVehicleMakeStats(vehicleMakes);

        let vehicleMakePie = (
          <Pie
            options={{ legend: { display: false } }}
            data={formattedVehicleMakeStats}
          />
        );
        const vehicleMakeChart = {
          title: texts.vehicleMakePie,
          description: undefined,
          image: vehicleMakePie,
        };
        charts.push(vehicleMakeChart);
      } else if (reportType === "validations") {
        title = `${
          locations === "all_locations"
            ? reportTexts.allLocations
            : businessLocations[locations].locationConfig.name
        } / ${reportTypes.validations}: ${reportData.startDate} - ${
          reportData.endDate
        } `;
        header = [
          texts.vehicle,
          texts.location,
          texts.date,
          texts.paymentTime,
          texts.user,
          texts.validation,
          texts.validation,
          texts.ticketNumber,
          texts.notes,
        ];
        let totalValidated = 0;
        let sortedDates = Object.keys(reportVehiclesByDay).sort(
          sortDDMMYYYDates
        );
        sortedDates.forEach((date) => {
          reportVehiclesByDay[date].vehicles.forEach((vehicle) => {
            var payments = vehicle.payment.list;
            for (var e in payments) {
              // !!!!!!!!!!!! CHANGE 'pos' to 'auth' once we have auth set up !!!!!!!!!!!!1
              if (
                !payments[e].deleted &&
                payments[e].paymentMethod === "auth"
              ) {
                let amount = `${payments[e].validationValue} ${
                  payments[e].validation === "percent" ? "%" : "$"
                }`;
                if (
                  payments[e].validation === "percent" &&
                  payments[e].amount === 0
                ) {
                  totalValidated =
                    Math.round(
                      useTotalAmount(
                        businessLocations[vehicle.locationId].locationConfig
                          .payment.rateConfig,
                        vehicle
                      ).charge *
                        payments[e].validationValue *
                        1
                    ) / 100;
                } else if (payments[e].validation === "percent") {
                  totalValidated = payments[e].amount * -1;
                } else {
                  totalValidated = payments[e].validationValue;
                }
                let user =
                  businessUsers[payments[e].user] !== undefined
                    ? `${businessUsers[payments[e].user].name} ${
                        businessUsers[payments[e].user].lastname
                      }`
                    : payments[e].user;
                let vehiclePlate = vehicle.plateNumber || `id - ${vehicle.id} `;
                let location =
                  businessLocations[vehicle.locationId].locationConfig.name;
                let paymentTimestamp = new Date(payments[e].date * 1000);
                let paymentTime = new Date(paymentTimestamp).toLocaleString(
                  "es-MX"
                );
                let ticketNumber = vehicle.card;
                let notesArr = [];
                Object.keys(vehicle.notes).forEach((noteKey) =>
                  notesArr.push(vehicle.notes[noteKey].text)
                );
                let info = payments[e].info;

                body.push([
                  vehiclePlate,
                  location,
                  date,
                  paymentTime,
                  user,
                  amount,
                  totalValidated,
                  ticketNumber,
                  info,
                ]);
              }
            }
          });
        });
        const paymentReducer = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue[5].substring(1));
        const paymentTotal = body.reduce(paymentReducer, 0);
        footer = ["", "", "", "", "", ""];

        //#######################################
        // Gráfico de validaciones
        // let formattedPaymentMethodStats = userValidationRegistryStats(
        //   paymentMethodCount
        // );
        // let paymentMethodPie = (
        //   <Pie data={formattedPaymentMethodStats.methodStats} />
        // );
        // const paymentMethodChart = {
        //   title: texts.paymentMethodPie,
        //   description: `${texts.totalPayments}: ${roundNumber(
        //     formattedPaymentMethodStats.totalPayments
        //   )} `,
        //   image: paymentMethodPie,
        // };
        // charts.push(paymentMethodChart);
      } else if (reportType === "incidents") {
        console.log("vehiclesReport", incidentsReport);

        title = `${
          locations === "all_locations"
            ? reportTexts.allLocations
            : businessLocations[locations].locationConfig.name
        } / ${reportTypes.validations}: ${reportData.startDate} - ${
          reportData.endDate
        } `;
        header = [texts.date, texts.incident, texts.user, texts.location];

        Object.values(incidentsReport).map((a) => {
          console.log("vehiclesReport", a);
          return body.push([
            fechahora(a.date.seconds),
            a.incident,

            businessUsers[a.userId] ? businessUsers[a.userId].name : a.userId,
            businessLocations[a.locationId].locationConfig.name,
          ]);
        });
        footer = ["", "", "", "", "", ""];
      }
      console.log("vehiclesReport", body);

      csvData.push(header);
      csvData.push(...body);
      csvData.push(footer);
      return { csvData, title, charts, vehiclesReport };
    });
}

export function savePDF({ languageObject, reportData, title }) {
  const texts = languageObject.authorizeUserdViews.reports;
  const doc = new jsPDF();
  var lMargin = 15; //left margin in mm
  var rMargin = 15; //right margin in mm
  var pdfInMM = 210; // width of A4 in mm

  console.log(title);
  if (title) {
    var lines = doc.splitTextToSize(title, pdfInMM - lMargin - rMargin);
    doc.text(lMargin, 15, lines);

    //    doc.text(7, 15, title);
  }
  let pdfBody = [...reportData];
  pdfBody.shift();
  autoTable(doc, {
    head: [reportData[0]],
    body: [...pdfBody],
    startY: 30,
    margin: { horizontal: 7 },
  });
  doc.save(`${texts.menuName}.pdf`);
}

export function getHourlyTraffic(reportVehicles) {
  let vehiclesByHour = [
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ];

  Object.keys(reportVehicles).forEach((vehicleKey) => {
    let vehicleStartime = new Date(
      reportVehicles[vehicleKey].startTime.seconds * 1000
    ).getHours();
    vehiclesByHour[vehicleStartime] += 1;
  });

  const hourlyTraffic = {
    labels: [
      "0:00",
      "1:00",
      "2:00",
      "3:00",
      "4:00",
      "5:00",
      "6:00",
      "7:00",
      "8:00",
      "9:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    datasets: [
      {
        backgroundColor: "#000",
        data: vehiclesByHour,
      },
    ],
  };
  return hourlyTraffic;
}

export function getTrafficByDateChartData(vehiclesByDay, texts) {
  let trafficDateLabels = Object.keys(vehiclesByDay).sort(sortDDMMYYYDates);
  let trafficNumbers = [];
  trafficDateLabels.forEach((date) => {
    trafficNumbers.push(vehiclesByDay[date].vehicles.length);
  });

  const lineData = {
    labels: trafficDateLabels,
    datasets: [
      {
        label: texts.traffic,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: trafficNumbers,
      },
    ],
  };

  return lineData;
}

export function getIncomeByLocationChartData(incomeReport, businessLocations) {
  let locationsIncomeArr = Object.entries(incomeReport.locations);
  let locationsLabels = [];
  let locationsIncomes = [];
  let highlights = [];

  console.log("locations ", locationsIncomeArr);
  locationsIncomeArr.forEach((incomeArr) => {
    if (incomeArr[1] !== -0) {
      locationsLabels.push(businessLocations[incomeArr[0]].locationConfig.name);
    }
    locationsIncomes.push(incomeArr[1]);
    highlights.push("#FFDF00");
  });
  const pieData = {
    labels: locationsLabels,
    datasets: [
      {
        data: locationsIncomes,
        backgroundColor: ["#9e9e9e", "#000", "#fff"],
        hoverBackgroundColor: highlights,
      },
    ],
  };

  return pieData;
}

export function dateToYYYYMMDD(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  let YYYYMMDD = yyyy + "-" + mm + "-" + dd;
  return YYYYMMDD;
}

export function dateToHHMM(date) {
  let HH = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  let MM = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${HH}:${MM}`;
}

const today = new Date();
let yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
let thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(today.getDate() - 30);
let sixtyDaysAgo = new Date(today);
sixtyDaysAgo.setDate(today.getDate() - 60);

let yearAgo = new Date(today);
yearAgo.setDate(today.getDate() - 365);

export { today, yesterday, thirtyDaysAgo, sixtyDaysAgo, yearAgo };
