export const Strings = {
  en: {
    businessName: "Smart Valet",
    slogan: "Smart Valet",
    account: {
      SesionView: {
        logIn: "Log in",
        registry: "Register",
        closeSesion: "Log out",
      },
      Login: {
        title: "Log In",
        emailInput: "Email",
        passwordInput: "Password",
        logInButtom: "Log In",
        passwordRestore: "Restore Password",
        backToLogIn: "Back to Log In",
      },
      CreateAcount: {
        title: "Create Acount",
        nameInput: "Name",
        lastnameInput: "Last Name",
        phoneInput: "Phone",
        phoneError: "Invalid phone",
        emailInput: "Email",
        emailError: "Invalid e-mail",
        emailUsed: "E-mail used try to recover you password",
        passwordInput: "Password",
        passwordConfirmInput: "Password confirm",
        passwordConfirmError: "Error: not coincidence in two passwords",
        logInButtom: "Create Account",
        registryConfirmation: "Congratulation you already are log In",
      },
    },
    footer: {
      AboutUs: {
        title: "Smart Valet",
        paragraph1: "Valet parking Software",
        paragraph12: "",
      },
      Contact: {
        title: "Contact",
        mail: "Correo: info@smartvalet.app",
        facebookLink: "",
        facebookText: "",
        phone: "Teléfono: +507 6584-2353",
      },
    },
    homeView: {
      menuName: "Home",
      link: "/home",
      title: "Smart Valet",
      text: "Welcome to your Valet Parking Solution",
      us: "Software to handel Valet Parking Business",
      contact: "E-mail: gerencia@smartvalet.app",
      phone: "Phone: +507 6584-2353",
    },
    aboutView: {
      menuName: "About us",
      link: "/about",
    },
    techView: {
      menuName: "Tech",
      link: "/tech",
    },
    contactView: {
      menuName: "Contact",
      link: "/contact",
    },
    authorizeUserdViews: {
      liveView: {
        menuName: "Live View",
        link: "/liveview",
        description: "Select the location you want to see",
        locationName: "Location name:",
        vehicleRequested: "Requested",
        vehicleRecovering: "Arriving",
        vehiclePlatform: "Ready",
        knowVehicleStatus: "know your vehicle status",
      },
      vehicleRequest: {
        menuName: "Vehicle Request",
        link: "/vehiclerequest",
      },
      reports: {
        menuName: "Reports",
        link: "/reports",
        loading: "Loading",
        reportTypeLabel: "Report Type",
        reportLocationLabel: "Locations",
        reportStartDateLabel: "Start Date",
        reportEndDateLabel: "End Date",
        generateReport: "Generate Report",
        allLocations: "All Locations",
        income: "Income",
        incomeDescription: "Total income",
        traffic: "Traffic",
        trafficDescription: "Total traffic",
        CSVLink: "Download Report as CSV",
        pdf: "Open PDF",
        reportTypes: {
          general: "General",
          income: "Income",
          incomeOnlyParking: "Income only Parking",
          traffic: "Traffic",
          turnoverTime: "Vehicle Turnover Time",
          validations: "Validations",
          vehicleDetails: "Vehicle Details",
          incidents: "Incidents",
        },
        reportDefinitions: {
          date: "Date",
          incident: "Incidet",
          user: "User",
          income: "Income",
          traffic: "Traffic",
          vehicle: "Vehicle",
          location: "Location",
          payment: "Payment",
          validation: "Validation",
          startTime: "Entrance time",
          endTime: "Exit time",
          turnoverTime: "Turnover (D:HH:MM:SS)",
          avgTurnover: "Average turnover",
          paymentMethod: "Payment Method",
          pay: "Pay",
          pendingPay: "Pending",
          cash: "Cash",
          pos: "Point of Sale",
          online: "Online",
          auth: "Validation",
          Tienda: "Tienda",
          unpaid: "Unpaid vehicle",
          hourlyTraffic: "Traffic by hour",
          totalTraffic: "Total Traffic",
          make: "Make",
          model: "Model",
          rate: "Rate",
          gate: "Gate",
          color: "Color",
          detail: "Detail",
          openDetail: "Open",
          paymentMethodPie: "Payment methods",
          ticketNumber: "Ticket number",
          paymentTime: "Payment time",
          user: "User",
          notes: "Notes",
          totalPayments: "Total number of payments",
          vehicleMakePie: "Vehicles by make",
        },
      },
      valetView: {
        menuName: "Valet View",
        link: "/valet",
        valetTitle: "Valet",
        scannerTitle: "Scanner",
        scannerButtom: "Scann Card",
        lastScan: "Last Scann",
        vehiclesReport: "Cars",
        parkingReport: "Parking Status",
        paymentsReport: "Payments",
        incidents: "Incidents",
        incidentsRegistry: "Registry a Incident",
        saveIncident: "Save Incident",
        vehicleRequested: "Vehicle Requested",
        vehicleRequestedDetail: "Vehícles ordered by first requested to last",
        vehicleDetail: "Car Detail",
        vehicleTableTitles: {
          brand: "Brand",
          color: "Color",
          card: "Card",
          key: "Key",
        },
      },
      vehicleDetail: {
        card: {
          createdBy: "Create by",
        },
        payment: {
          title: "Income / Payments",
          user: "User",
          description: "Description",
          date: "Date / Time",
          amount: "Ampont",
          pending: "Pending",
          cashPayment: "Cash Payment",
          posPayment: "Pos Payment",
          onLinePayment: "On Line Payment",
        },
        changes: {
          title: "Changes",
          user: "User",
          description: "Description",
          date: "Date / Time",
        },
        notes: {
          title: "Notes",
          user: "User",
          description: "Note",
          date: "Date / Time",
          addNote: "Add note",
          saveNote: "Save Note",
        },
        photos: {
          title: "Photos",
          user: "User",
          description: "Photo",
          date: "Date / Time",
          notPhoto: "No Photos in this vehicle",
        },
        status: {
          title: "Status",
          changeStatus: "Select status to set",
          closeVehicle: "Set vehicle as closed",
          closeVehicleButton: "Close vehicle",
          openVehicleButton: "Closed vehicle",
          date: "Fecha / Hora",
          scanned: "Scanned",
          parking: "Parking",
          parked: "parked",
          recovering: "Recovering",
          platform: "Platform",
          taken: "Taken",
        },
      },

      locations: {
        menuName: "Locations",
        link: "/locations",
        locationsTitle: "Locations",
        locationsContainer: {
          add: "Add New Location",
          locationName: "Name of the Location",
          defaultLocationName: "New Location",
          locationNameError: "Choose a name for the new location.",
          locationDescription: "Location Description",
          defaultLocationDescription: "Description of the New Location",
          locationDescriptionError:
            "Write a short description of the new location.",
        },
        locationCard: {
          allLocationCard: "All Locations Resume",
          availablePlaces: "Available Spaces",
          totalPlaces: "Total Spaces",
          ocupedPlaces: "Occupied Spaces",
          activeUsersCount: "Active Users",
          income: "Total Income",
          openLocationDetailButtom: "Open Location Detail",
          openValetViewButtom: "Open Valet View",
        },
        locationDetails: {
          startShift: "Shift Start",
          endShift: " Shift End",
          nextDay: "Next day at ",
          openGeneralDataConfigButtom: "Change General Data",
          paymentMethodsTitle: "Payment Methods",
          receiptMethodsTitle: "Receipt Methods",
          rateConfigTitle: "Rate Configuration",
          gateConfigTitle: "Gate Configuration",
          receipt: "Receipt",
          printer: "Printer",
          whatsappDetail:
            "Select this option to sente whatsapp messages to valet users, this service hava an aditional cost (to see details Contact to Smart Valet Service)",
          card: "Card",
          business: "Business",
          receiptConfig: "Receipt configuration object",
          qrConfig: "QR CODE configuration object",
          web: "Website",
          viewLocationOnMap: "View on map",
          locationDescription: "Location descriptión",
          locationAdress: "Adress",
          locationUsersTitle: "Users",
          locationPaymentTitle: "Income & Payment Methods",
          locationZonesTitle: "Zones & places",
          editLocationName: "Location Name",
          defaultCountryCode: "Default Country Code",
          editLocationDescription: "Location Description",
          editLocationAdress: "Adress",
          editLocationLat: "Latitude",
          editLocationLng: "Longitude",
          editZoneTitle: "Zones",
          editZoneName: "Zone Name",
          hideZone: "Archived Zone",
          editZonePriority: "Zone Priority",
          editPlaceName: "Place Name",
          editPlaceSelectTitle:
            "Pick zone type: Zone widh specific name and place a priority or places created by number",
          editPlaceSelectNoNamed: "Places created by number",
          editPlaceSelectNamed: "Puestos detallados con nombre y prioridad",
          zonePlacesNumber: "Number of no named places",
          editPlaces: "Additional Places",
          placeOcupade: "Ocupade",
          placeEmpty: "Empty",
          placeDefaultName: "place",
          OP: "OP",
          edit: "Edit",
          priority: "Priority Number",
          places: "Number of places",
          save: "Save",
          cancel: "Cancel",
          add: "Add",
          alert: {
            title: "Are you sure?",
            subtitle: "Are you sure you want to delete this zone?",
            cancel: "Cancel",
            remove: "Remove",
            removePlaceTitle: "Are you sure you want to delete this place?",
          },
          addStep: "Add Step",
          minute: "Minutes",
          hour: "Hours",
          timeUnit: "Time Unit",
          extraProducts: "Extra Products",
          additionalInfo: "Additional Info",
          productName: "Name",
          checkbox: "Checkbox",
          yes: "Yes",
          no: "No",
          productDescription: "Description",
          productPrice: "Price",
          productErrorMessage:
            "Please make sure the product has a name, description, and price.",
          additionalItemErrorMessage: "Please make sure the item has a name.",
          newName: "New Product",
          newDescription: "New Product Description",
          rateName: "Rate Name",
          valetRate: "Valet Rate (Optional)",
          valetRateError: "Please give this rate for the Valet",
          freeTime: "Free minutes affter Payment",
          freeTimeError: "Set a value in minutes",
          importSteps: "Import Steps",
          rateIdCustom: "Rate Id",
          gateName: "Gate Name",
          rateNameError: "Please give this rate a name.",
          rateIdCustomError: "Please give this rate Id.",
          gateNameError: "Please give this gate a name.",
          currentRate: "FconfigRate to Edit",
          currentGate: "Gate to Edit",
          hidden: "Hidden in App",
          defaultRate: "Default Rate",
          defaultGate: "Default Gate",
          addRate: "Add Rate",
          addGate: "Add Gate",
          addItem: "Add Item",
        },
        rates: {
          step: "Step",
          price: "Price",
          hours: "Hours",
          minutes: "Minutes",
          error: "Check this field",
          removeStep: "Delete Step",
        },
        vehiclesAdd: {
          title: "Vehicle Add",
          menuName: "Vehicle Add",
          link: "/vehicleadd",
          plateNumber: "Plate / Model",
          exotic: "Ex",
          manual: "M",
          alertNotes: "Alert de notes",
          notes: "Notes",
          saveCarButton: "Save Vehicle",
          checkRequiredData: "Check Required Data",
          addPhoto: "Photo",
          findBrand: "Find Brand",
          brandsTitle: "Brands",
          colorsTitle: "Colors",
          save: "Save",
          saved: "Saved!!",
          card: "Card",
          key: "Key",
          rate: "Rate",
          gate: "Gate",
          extrasTitle: "Extras",
          extraProducts: "Shop",
          extraAdditions: "Additions",
          price: "Price",
          invalidCard: "Invalid Card",
          printReceipt: "Print Receipt",
        },
      },
      users: {
        menuName: "Users",
        link: "/users",
        authorizeUsersTitle: "Authorized Users",
        userListTableTitles: {
          name: "Name",
          lastname: "Last Name",
          mail: "Mail",
          profile: "Profile",
          phoneNumber: "Phone",
          open: "Open",
        },
        userProperties: {
          name: "Name",
          lastname: "Last Name",
          mail: "Email",
          phoneNumber: "Phone",
          registerDate: "Registration Date",
          profile: "Profile",
          authroized: "Authorized",
          id: "Id",
          send: "Save",
          userTitle: "User Profile",
        },
        profiles: {
          unauthorized: "Unauthorized",
          locationLeader: "Location Leader",
          master: "Master",
          ticketsValidation: "Tickets Validation",
          valet: "Valet",
          locations: "Locations",
          ticketsValidationAndReports: "Tickets Validation and Reports",
          incomeReport: "Income Report",
        },
        payRoll: {
          shifts: "Shifts",
          hourlyWage: "Hourly Wage",
          error: "Please verify this field",
          shift: "Shift",
          date: "Date",
          startTime: "Start Time",
          endTime: "End Time",
          shiftTime: "Shift Time",
          shiftPay: "Shift Pay",
          saveChanges: "Save Changes",
          deleteShift: "Delete Shift",
          location: "Location",
          reportGenerator: {
            allUsers: "All Users",
            reportTypeLabel: "Report Depth",
            reportStartDateLabel: "Start Date",
            reportEndDateLabel: "End Date",
            generateReport: "Generate Report",
            CSVLink: "Download as CSV",
            pdf: "Open PDF",
            name: "Name",
            numberOfShifts: "Number of Shifts",
            hoursWorked: "Hours worked",
            avgPay: "Average Pay per Hour",
            totalPay: "Total Pay",
            date: "Date",
            startTime: "Start Time",
            endTime: "End Time",
            shiftTime: "Shift Time (hrs)",
            shiftPay: "Shift Pay",
            hourlyWage: "Hourly Wage",
            avgHoursPerShift: "Avg. Hours per Shift",
            startDate: "Report Start Date",
            endDate: "Report End Date",
            location: "Location",
          },
        },
        errorMessages: {
          name: "Name is a required field",
          lastname: "Last Name is a required field",
          phoneNumber: "Enter the 10 digit phone number",
          mail: "Enter the email adress with the format __@__.__",
          zoneName: "Add a name for the zone",
          priority: "Add a priority number between 1 and 100",
          zonePlacesNumber: "Add number off places to create",
          saveSuccess: "The change was saved successfully.",
          saveFailure: "The change was not saved. Try again later.",
          locationName: "Add a name for this location.",
          locationDescription: "Add a description for this location.",
          locationAdress: "Add an adress for this location.",
          locationLat: "Add the lattitude for this location.",
          locationLng: "Add the longitude for this location.",
          business: "Add the name of this business.",
          web: "Add your business's webpage.",
          additionalPlaces: "Add the number of additional parking places.",
        },
        addUserButtom: "Add user",
        emailUserToAuthorize: "email",
        profileUserToAuthorize: "Profile User",
        sendToAuthorize: "Authorize",
        registration: {
          title: "How to register a new user",
          step1: "Send the new user this link:",
          step2:
            "The new user registers there and tells you what email adress they registered with.",
          step3: "Enter that email adress below and submit!",
          step4: "Then you can edit their profile at",
          addUserButtom: "Add User",
          inputPlaceholder: "New user email",
        },
      },
      chat: {
        menuName: "Chat",
        link: "/chat",
        sendMessage: "Send a message",
      },
      ticketsValidation: {
        menuName: "Ticket Validation",
        link: "/ticketsValidation",
        findVehicle: "Find vehicle",
        findeVehicleDetail: "Find vehicle by card",
        closeAll: "Close All Actives Vehicles",
        closeAllConfirm: "Confirm to close all actives vehícles",
        closeAllConfirmYes: "YES",
        closeAllConfirmNO: "NO",

        usersValidation: {
          name: "Name",
          locations: "Locations",
          labelSelect: "Validation Type",
          value: "Auth Value",
          no: "Not",
          percent: "Percent",
          timeBased: "Time Based",
          amount: "Amount",
          notAuthorizedInLocation:
            "Not authorized in this location, update user profile in Users View",
          notAuthorizedInLocationUser:
            "Not authorized in this location, contact Master User",
        },
        table: {
          dateTime: "Date / Tieme",
          brand: "Brand",
          model: "Model",
          color: "Color",
          key: "Key",
          card: "card",
          charges: "Charges",
          payments: "Payments",
          pending: "Pending",
          allVehicles: "All Vehicles",
          closedVehicles: "Closed Vehicles",
          openVehicles: "Open Vehicles",
          openDetail: "Open",
          status: "Status",
          gate: "Gate",
        },
        tablePayment: {
          date: "Date Time",
          description: "Description",
          user: "User",
          amount: "Amount",
          name: "Description",
          pending: "Pending",
          validate: "Validate rate",
          validated: "Validated",
          info: "Validation Reazon (Optional)",
        },
      },
      payRoll: {
        menuName: "Pay Roll",
        link: "/payroll",
        badgeDescriptions: {
          numberOfWorkers: "Number of workers",
          hoursWorked: "Hours worked this quincena",
          payRollTotal: "Total payroll this quincena",
        },
      },
      rates: {
        step: "Step",
        price: "Price",
        hours: "Hours",
        minutes: "Minutes",
        error: "Check this field",
        removeStep: "Delete Step",
      },
    },
    generalData: {
      required: "Required",
      businessSelectTitle: "Business",
      colors: {
        black: "Black",
        blue: "Blue",
        grey: "Grey",
        maroon: "Marron",
        red: "Red",
        white: "White",
        yellow: "Yellow",
        empty: "not defined",
        beige: "Beige",
        green: "Green",
        orange: "Orange",
        pink: "Pink",
      },
      status: {
        canceled: {
          description: "Canceled by authorize User",
          name: "Canceled",
        },
        parked: {
          description: "Car already parked",
          name: "Parked",
        },
        parking: {
          description: "Car going to one place",
          name: "Parking",
        },
        platform: {
          description: "Vehicle ready to be taken",
          name: "Platform",
        },
        recovering: {
          description: "Recovering vehicle",
          name: "Recovering",
        },
        requested: {
          description: "Vehicle requested by owner",
          name: "Requested",
        },
        scanned: {
          description: "Scanned",
          name: "Scanned",
        },
        taken: {
          description: "Vehicle already taken by owner",
          name: "Taken",
        },
        arriving: {
          description: "Vehicle Arriving",
          name: "Arriving",
        },
      },
    },
  },
  es: {
    businessName: "Smart Valet",
    slogan: "Smart Valet",
    account: {
      SesionView: {
        logIn: "Inicia Sesión",
        registry: "Regístrate",
        closeSesion: "Cerrar Sesión",
      },
      Login: {
        title: "Inicia Sesión",
        emailInput: "Correo",
        passwordInput: "Contraseña",
        logInButtom: "Inicia sesión",
        passwordRestore: "Recuperar Contraseña",
        backToLogIn: "Regresar al inicio de sesión",
      },
      CreateAcount: {
        title: "Crear cuenta",
        nameInput: "Nombres",
        lastnameInput: "Apellidos",
        phoneInput: "Teléfono",
        phoneError: "Teéfono inválido",
        emailInput: "Correo",
        emailError: "Correo inválido",
        emailUsed: "Correo ya ustilizado intenta recuperar la contraseña",
        passwordInput: "Contraseña",
        passwordConfirmInput: "Confirma Contraseña",
        passwordConfirmError: "La confirmación de la contraseña no coincide",
        logInButtom: "Crear Cuenta",
        registryConfirmation:
          "Felicidades completaste exitosamente el registro.",
      },
    },
    footer: {
      AboutUs: {
        title: "Smart Valet",
        paragraph1: "Software para la gestión de valet Parking",
        paragraph12: "",
      },
      Contact: {
        title: "Contacto",
        mail: "Correo: info@smartvalet.app",
        facebookLink: "",
        facebookText: "",
        phone: "Teléfono: +507 6584-2353",
      },
    },
    homeView: {
      menuName: "Inicio",
      link: "/home",
      title: "Smart Valet",
      text: "Bienvenido a tu solución de Valet Parking.",
      us: "Software de gestión para valet parking",
      contact: "Correo: gerencia@smartvalet.app",
      phone: "Teléfono: +507 6584-2353",
    },
    aboutView: {
      menuName: "Nosotros",
      link: "/about",
    },
    techView: {
      menuName: "Tecnologia",
      link: "/tech",
    },
    contactView: {
      menuName: "Contacto",
      link: "/contact",
    },
    authorizeUserdViews: {
      liveView: {
        menuName: "Tiempo Real",
        link: "/liveview",
        description: "Selecciona la locación que quieres visualizar",
        locationName: "Nombre de la locación",
        vehicleRequested: "Solicitado",
        vehicleRecovering: "Buscando",
        vehiclePlatform: "Listo para entrega",
        knowVehicleStatus: "Conoce el estatus de tu vehículo",
      },
      vehicleRequest: {
        menuName: "Registro de vehiculos",
        link: "/vehiclerequest",
      },
      reports: {
        menuName: "Reportes",
        link: "/reports",
        loading: "Cargando",
        reportTypeLabel: "Tipo de reporte",
        reportLocationLabel: "Ubicaciones",
        reportStartDateLabel: "Fecha de Inicio",
        reportEndDateLabel: "Fecha de terminación",
        generateReport: "Generar Reporte",
        allLocations: "Todos las Ubicaciones",
        income: "Ingresos",
        incomeDescription: "Ingresos totales",
        traffic: "Tráfico",
        trafficDescription: "Tráfico total",
        CSVLink: "Descarga Reporte como CSV",
        pdf: "Abre PDF",
        reportTypes: {
          general: "General",
          income: "Ingresos",
          incomeOnlyParking: "Ingresos solo Parking",
          traffic: "Tráfico",
          turnoverTime: "Tiempo de permanencia",
          validations: "Validaciones",
          vehicleDetails: "Detalles de vehículo",
          incidents: "Incidencias",
        },
        reportDefinitions: {
          date: "Fecha",
          incident: "Incidente",
          user: "Usuario",
          income: "Ingresos",
          traffic: "Tráfico",
          vehicle: "Vehículo",
          location: "Ubicación",
          payment: "Pago",
          validation: "Validación",
          startTime: "Hora de entrada",
          endTime: "Hora de salida",
          turnoverTime: "Tiempo de permanencia (D:HH:MM:SS)",
          avgTurnover: "Promedio de permanencia",
          paymentMethod: "Método de pago",
          pay: "Pagos",
          pendingPay: "Pendiente",
          cash: "Efectivo",
          pos: "Punto de venta",
          online: "Pago en linea",
          auth: "Validación",
          Tienda: "Tienda",
          unpaid: "Vehículo no cobrado",
          hourlyTraffic: "Tráfico por hora",
          totalTraffic: "Tráfico total",
          make: "Marca",
          model: "Modelo",
          rate: "Tarifa",
          gate: "Puerta",
          color: "Color",
          detail: "Detalle",
          openDetail: "Abrir",
          paymentMethodPie: "Métodos de pago",
          ticketNumber: "Número de boleto",
          paymentTime: "Hora del pago",
          user: "Usuario",
          notes: "Notas",
          totalPayments: "Número total de pagos",
          vehicleMakePie: "Vehículo por marca",
        },
      },
      valetView: {
        menuName: "Vista de Valet",
        link: "/valet",
        valetTitle: "Valet",
        scannerTitle: "Scanner",
        scannerButtom: "Escanear Tarjeta",
        lastScan: "Última tarjeta escaneada",
        vehiclesReport: "Vehículos",
        parkingReport: "Estacionamiento",
        vehicleRequested: "Solicitados",
        vehicleRequestedDetail:
          "Vehículos ordenados de la solicitud mas antigua a la más reciente",
        paymentsReport: "Caja",
        incidents: "Incidentes",
        incidentsRegistry: "Registrar un incidente",
        saveIncident: "Guardar incidente",
        vehicleDetail: "Detalle Vehículo",
        vehicleTableTitles: {
          brand: "Marca",
          color: "Color",
          card: "Tarjeta",
          key: "Llave",
        },
      },
      vehicleDetail: {
        card: {
          createdBy: "Creado por",
        },
        payment: {
          title: "Ingresos y pagos",
          user: "Usuario",
          description: "Descripción",
          date: "Fecha / Hora",
          amount: "Monto",
          pending: "Pendiente",
          cashPayment: "Registrar un pago en efectivo",
          posPayment: "Registrar un pago en terminal bancaria",
          onLinePayment: "Registrar un pago en Linea",
        },
        changes: {
          title: "Cambios",
          user: "Usuario",
          description: "Descripción",
          date: "Fecha / Hora",
        },
        notes: {
          title: "Notas",
          user: "Usuario",
          description: "Nota",
          date: "Fecha / Hora",
          addNote: "Agregar una nota",
          saveNote: "Guardar Nota",
        },
        photos: {
          title: "Fotos",
          user: "Usuario",
          description: "Foto",
          date: "Fecha / Hora",
          notPhoto: "Este vehículo no tiene fotos",
        },
        status: {
          title: "Estatus",
          changeStatus: "Selecciona el estatus a asignar",
          closeVehicle: "Finalizar vehículo",
          closeVehicleButton: "Finalizar",
          openVehicleButton: "Vehículo finalizado",
          date: "Fecha / Hora",
          scanned: "Escaneado",
          parking: "Estacionando",
          parked: "Estacionado",
          recovering: "Recuperando",
          platform: "Plataforma",
          taken: "Entregado",
        },
      },
      locations: {
        menuName: "Ubicaciones",
        link: "/locations",
        locationsTitle: "Ubicaciones",
        locationsContainer: {
          add: "Agregar Nueva Ubicación",
          locationName: "Nombre de la Ubicación",
          defaultLocationName: "Ubicación Nueva",
          locationNameError: "Escoge un nombre por tu nueva ubicación.",
          locationDescription: "Descripción de la Ubicación",
          defaultLocationDescription: "Descripción de tu nueva ubicación",
          locationDescriptionError:
            "Escribe una pequeña descripción de la nueva ubicación.",
        },
        locationCard: {
          allLocationCard: "Resumen de todas las ubicaciones",
          availablePlaces: "Disponibles",
          totalPlaces: "Total",
          ocupedPlaces: "Ocupados",
          openLocationDetailButtom: "Abrir detalles",
          openValetViewButtom: "Vista de Valet",
          activeUsersCount: "Usuarios Activos",
          income: "Ingresos Totales",
        },
        locationDetails: {
          startShift: "Inicio de turno",
          endShift: "Fin de turno",
          nextDay: "Día siguiente a las ",
          openGeneralDataConfigButtom: "Ajustar Data General",
          paymentMethodsTitle: "Métodos de Pago",
          receiptMethodsTitle: "Métodos de Recibo",
          rateConfigTitle: "Configuración de Cobro",
          gateConfigTitle: "Configuración Puertas de Acceso",
          receipt: "Recibo",
          printer: "Impresora",
          whatsappDetail:
            "Selecciona esta opción para usar los mensajes de whatsapp como recibo para los usuairos del servicio, este servicio tiene un costo adicional (Consultar con Smart Valet)",
          card: "Tarjeta",
          type: "Tipo",
          value: "valor (aplica para tipo texto)",
          visible: "Visible",
          receiptConfigTitle: "Selecciona la linea a agregar para el recibo",
          receiptConfig: "Objeto de configuración del recibo",
          qrConfig: "Objeto de configuración para el código QR",
          viewLocationOnMap: "Ver la ubicación en el mapa",
          locationDescription: "Descriptión de la ubicación",
          locationAdress: "Dirección",
          locationUsersTitle: "Usuarios",
          locationPaymentTitle: "Ingresos y metodos de pago",
          locationZonesTitle: "Zonas y lugares",
          editLocationName: "Nombre de la ubicación",
          defaultCountryCode: "Código de país por defecto",
          editLocationDescription: "Descripcion de ubicación",
          editLocationAdress: "Dirección",
          editLocationLat: "Latitud",
          editLocationLng: "Longitud",
          editZoneTitle: "Zonas",
          editZoneName: "Nombre de Zona",
          hideZone: "Zona Archivada",
          editZonePriority: "Prioridad de Zona",
          editPlaceName: "Nombre del lugar",
          editPlaceSelectTitle:
            "Selecciona el tipo de zona: Zona con puestos detallados y con prioridad o zona generada por lote",
          editPlaceSelectNoNamed:
            "Zona de puestos por lote sin nombre ni prioridad",
          editPlaceSelectNamed: "Puestos detallados con nombre y prioridad",
          zonePlacesNumber: "Número de puestos",
          editPlaces: "Lugares adicionales",
          placeOcupade: "Ocupado",
          placeEmpty: "Vacio",
          placeDefaultName: "Puesto",
          OP: "LE",
          edit: "Editar",
          priority: "Número de Prioridad",
          places: "Número de lugares",
          save: "Guardar",
          cancel: "Cancelar",
          add: "Agregar",
          alert: {
            title: "¿Estás seguro?",
            subtitle: "¿Estás seguro que quieres borrar esta zona?",
            cancel: "Cancelar",
            remove: "Borrar",
            removePlaceTitle: "¿Estás seguro que quieres borrar este lugar?",
          },
          addStep: "Agrega Paso",
          minute: "Minutos",
          hour: "Horas",
          timeUnit: "Unidad de tiempo",
          extraProducts: "Productos Adicionales",
          additionalInfo: "Información Adicional",
          productName: "Nombre",
          checkbox: "Casilla de Selección",
          yes: "Si",
          no: "No",
          productDescription: "Descripción",
          productPrice: "Precio",
          productErrorMessage:
            "Favor de revisar que el producto tenga nombre, descripción y precio.",
          additionalItemErrorMessage:
            "Favor de revisar que el elemento tenga nombre.",
          newName: "Nuevo Producto",
          newDescription: "Descripción del Nuevo Producto",
          rateName: "Nombre de tarifa",
          valetRate: "Tarifa específica de valet (Opcional)",
          valetRateError: "Agrega una tarifa predefinida o dejar en cero",
          freeTime: "Minutos libres post pago",
          freeTimeError: "Establece un tiempo e minutos",
          importSteps: "Importar pasos",
          rateIdCustom: "Id de la tarifa",
          gateName: "Nombre de Puerta",
          rateNameError: "Favor de agregar un nombre a esta tarifa.",
          rateIdCustomError: "Favor de agregar un id para la tarifa.",
          gateNameError: "Favor de agregar un nombre a esta puerta.",
          currentRate: "Tarifa a Editar",
          currentGate: "Puerta a Editar",
          hidden: "Oculto en App",
          defaultRate: "Tarifa por Defecto",
          defaultGate: "Puerta por Defecto",
          addRate: "Agrega Tarifa",
          addGate: "Agrega Puerta",
          addItem: "Agrega Elemento",
        },
        rates: {
          step: "Paso",
          price: "Precio",
          hours: "Horas",
          minutes: "Minutos",
          error: "Revisa este campo",
          removeStep: "Borrar Paso",
        },
      },
      users: {
        menuName: "Usuarios",
        link: "/users",
        authorizeUsersTitle: "Usuarios Autorizados",
        userListTableTitles: {
          name: "Nombres",
          lastname: "Apellidos",
          mail: "Correo",
          profile: "Perfil",
          phoneNumber: "Teléfono",
          open: "Abrir detalle",
        },
        userProperties: {
          name: "Nombre",
          lastname: "Apellido",
          mail: "Correo",
          phoneNumber: "Teléfono",
          registerDate: "Fecha de registro",
          profile: "Perfíl",
          authroized: "Autorizado",
          id: "Id",
          send: "Guardar",
          userTitle: "Datos del usuario",
        },
        profiles: {
          unauthorized: "No autorizado",
          locationLeader: "Líder de sucursal",
          master: "Master",
          ticketsValidation: "Validación de boletos",
          valet: "Valet",
          locations: "Ubicaciones",
          ticketsValidationAndReports: "Validación de boletos y Reportes",
          incomeReport: "Reporte de ingresos por parking",
        },
        payRoll: {
          shifts: "Turnos",
          hourlyWage: "Pago por Hora",
          error: "Favor de verificar este campo",
          shift: "Turno",
          date: "Fecha",
          startTime: "Hora de Entrada",
          endTime: "Hora de Salida",
          shiftTime: "Tiempo del Turno",
          shiftPay: "Pago del Turno",
          saveChanges: "Guardar Cambios",
          deleteShift: "Eliminar Turno",
          location: "Ubicación",
          reportGenerator: {
            allUsers: "Todos los Usuarios",
            reportTypeLabel: "Alcance del Reporte",
            reportStartDateLabel: "Fecha de Inicio",
            reportEndDateLabel: "Fecha de Terminación",
            generateReport: "Generar Reporte",
            CSVLink: "Descarga Reporte como CSV",
            pdf: "Abre PDF",
            name: "Nombre",
            numberOfShifts: "Número de Turnos",
            hoursWorked: "Horas Trabajados",
            avgPay: "Pago por Hora Promedio",
            totalPay: "Pago Total",
            date: "Fecha",
            startTime: "Hora de comienzo",
            endTime: "Hora de Terminación",
            shiftTime: "Tiempo del Turno (hrs)",
            shiftPay: "Pago del Turno",
            hourlyWage: "Pago por Hora",
            avgHoursPerShift: "Horas por turno (promedio)",
            startDate: "Fecha de Inicio de Reporte",
            endDate: "Fecha de Terminación de Reporte",
            location: "Ubicación",
          },
        },
        errorMessages: {
          name: "Nombre es un campo obligatorio",
          lastname: "Apellido es un campo obligatorio",
          phoneNumber: "Ingresa el número de telefono de 10 dígitos",
          mail: "Ingresa el correo con el formato __@__.__",
          zoneName: "Agrega un nombre para la zona",
          priority: "Ingresa un número de prioridad entre 1 y 100",
          zonePlacesNumber: "Ingresa el número de puestos a crear",
          saveSuccess: "Se guardó correctamente el cambio.",
          saveFailure: "No se guardó el cambio. Intenta de nuevo más tarde.",
          locationName: "Add a name for this location.",
          locationDescription: "Agrega una descripción para esta ubicación.",
          locationAdress: "Agrega la direción de esta ubicación.",
          locationLat: "Agrega la latitud para esta ubicación.",
          locationLng: "Agrega la longitud para esta ubicación.",
          business: "Agrega el nombre del negocio.",
          web: "Agrega la página web del negocio.",
          additionalPlaces: "Agrega el número de puestos adicionales .",
        },
        addUserButtom: "Agregar usuario",
        emailUserToAuthorize: "Correo",
        profileUserToAuthorize: "Perfil",
        sendToAuthorize: "Autorizar",
        registration: {
          title: "Cómo registrar un nuevo usuario",
          step1: "Manda esta liga al nuevo usuario:",
          step2:
            "El nuevo usuario se registrará ahí y te dirá que dirección de correo electrónico usó",
          step3:
            "Ingresa esa dirección de correo electrónico abajo y presiona el botón",
          step4: "Después ya podrás editar su cuenta en",
          addUserButtom: "Agregar Usuario",
          inputPlaceholder: "Correo de nuevo usuario",
        },
      },
      chat: {
        menuName: "Chat",
        link: "/chat",
        sendMessage: "Envia un mensaje",
      },
      ticketsValidation: {
        menuName: "Validar tickets",
        link: "/ticketsValidation",
        findVehicle: "Buscar vehículo",
        findeVehicleDetail: "Busca el vehículo por número de tarjeta",
        closeAll: "Finalizar todos los vehículos activos",
        closeAllConfirm:
          "Confirmas que deseas finalizar todos los vehículos activos",
        closeAllConfirmYes: "SI",
        closeAllConfirmNO: "NO",
        usersValidation: {
          name: "Nombre",
          labelSelect: "Tipo de Validación",
          value: "Valor Autorizado",
          locations: "Ubicaciones",
          no: "No",
          percent: "Porcentaje",
          timeBased: "Tiempo",
          amount: "Monto",
          notAuthorizedInLocation:
            "No autorizado para esta ubicación, modifica el perfil del usuario en la sección de usuario del sistema",
          notAuthorizedInLocationUser:
            "No autorizado para liberar tickets, Contacta al usuario administrador",
        },
        table: {
          dateTime: "Fecha / Hora",
          brand: "Marca",
          model: "Modelo/Placa",
          color: "Color",
          key: "Llave",
          card: "Tarjeta",
          charges: "Cargos",
          payments: "Pagos",
          pending: "Pendiente de pago",
          allVehicles: "Todos",
          closedVehicles: "Finalzados",
          openVehicles: "Activos",
          openDetail: "ver",
          status: "Estatus",
          gate: "Puerta",
        },
        tablePayment: {
          date: "Fecha / Hora",
          description: "Descripción",
          user: "Usuario",
          amount: "Monto",
          name: "Descripción",
          pending: "Saldo",
          validate: "Validar Tarifa",
          validated: "Vehículo ya validado",
          info: "Motivo de la validación (Opcional)",
        },
      },
      payRoll: {
        menuName: "Nómina",
        link: "/payroll",
        badgeDescriptions: {
          numberOfWorkers: "Número de trabajadores",
          hoursWorked: "Horas trabajadas esta quincena",
          payRollTotal: "Nómina total esta quincena",
        },
      },
      rates: {
        step: "Paso",
        price: "Precio",
        hours: "Horas",
        minutes: "Minutos",
        error: "Revisa este campo",
        removeStep: "Borrar Paso",
      },
      vehiclesAdd: {
        title: "Registrar Vehiculo",
        menuName: "Registrar Vehiculo",
        link: "/vehicleadd",
        plateNumber: "Placa / Modelo",
        exotic: "Ex",
        manual: "M",
        alertNotes: "Alerta de notas",
        notes: "Notas",
        saveCarButton: "Guardar Vehículo",
        checkRequiredData: "Revisa los campos requeridos",
        addPhoto: "Foto",
        findBrand: "Buscar marca",
        brandsTitle: "Marcas",
        colorsTitle: "Colores",
        notesInput: "Notas",
        save: "Guardar",
        saved: "Nota Guardada!!",
        card: "Tarjeta",
        key: "Llave",
        rate: "Tarifa",
        gate: "Puerta",
        extrasTitle: "Extras",
        extraProducts: "Tienda",
        extraAdditions: "Adicionales",
        price: "Precio",
        invalidCard: "Tarjeta Inválida",
        printReceipt: "Imprimir recibo",
      },
    },
    generalData: {
      required: "Requerido",
      businessSelectTitle: "Negocio",
      colors: {
        black: "Negro",
        blue: "Azul",
        grey: "Gris",
        maroon: "Marron",
        red: "Rojo",
        white: "Blanco",
        yellow: "Amarillo",
        empty: "No definido",
        beige: "Beige",
        green: "Verde",
        orange: "Naranja",
        pink: "Rosado",
      },
      status: {
        canceled: {
          description: "Registro Cancelado por el usuario master",
          name: "Cancelado",
        },
        parked: {
          description: "Vehiculo unbicado en un puesto definido",
          name: "Estacionado",
        },
        parking: {
          description: "Vehiculo en la vía a un pusto definido",
          name: "Estacionando",
        },
        platform: {
          description: "Vehículo listo para la entrega a su dueño",
          name: "Plataforma",
        },
        recovering: {
          description: "Vehiculo siendo recuperado",
          name: "Recuperando",
        },
        requested: {
          description: "Vehiculo solicitado por el propietario",
          name: "Solicitado",
        },
        scanned: {
          description: "Vehículo escameado",
          name: "Escaneado",
        },
        taken: {
          description: "Vehiculo entregado a su dueño",
          name: "Entregado",
        },
        arriving: {
          description: "Vehiculo Ingresando al valet sin escanear",
          name: "LLegando",
        },
      },
    },
  },
};
export const compareStrings = () => {
  var array = recorrerObjetoJsonLoopFind(Strings, "es", "en");
  if (array.length == 0) {
    return "NO HAY PENDIENTES POR AGREGAR AL IDIOMA INGLES";
  } else {
    console.error("Hay textos pendientes en el idioma INGLES");
    return array;
  }
};
export const recorrerObjetoJsonLoopFind = (objeto, langBase, langFind) => {
  var tab = 0;
  var langObjetcBase = objeto[langBase];
  var langObjetcFind = objeto[langFind];
  var Campos = [];
  recorrerObjetoJson(langObjetcBase, langObjetcFind);
  function recorrerObjetoJson(langObjetcBase, langObjetcFind) {
    for (var i in langObjetcBase) {
      if (
        langObjetcBase.hasOwnProperty(i) &&
        langObjetcFind.hasOwnProperty(i)
      ) {
        var valBase = langObjetcBase[i];
        var valFind = langObjetcFind[i];
        if (typeof valBase == "object") {
          recorrerObjetoJson(valBase, valFind);
        } else {
          // aquí si es atributo
          if (valFind == undefined) {
            Campos.push(JSON.stringify(langObjetcBase[i]));
          }
        }
      } else {
        Campos.push(i);
      }
    }
    return Campos;
  }
  return Campos;
};
